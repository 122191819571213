import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@mui/material';
import { useHistory } from 'react-router-dom';

// constantes
import {
  NIVEL_DE_CONFIANZA_AUTOREGISTRADO,
  MODAL_VERIFICAR_CIUDADANO_TEXT,
  MODAL_VERIFICAR_MAIL,
  CIUDADANO_NO_VISIBLE,
  ELIMINAR_CIUDADANO_TEXT,
  REVERIFICAR_CORREO_TEXT,
  REVERIFICAR_CORREO_LINK_TEXT,
  ACTIVAR_CUENTA_LINK_TEXT,
  ACTIVAR_CUENTA_TEXT,
  CUENTA_BLOQUEADA,
  DESBLOQUEO_CUENTA_LINK_TEXT,
  MODAL_EMAIL_NO_VALIDADO_CIUDADANO_TEXT,
  MODAL_CUENTA_NO_ACTIVADA_CIUDADANO_TEXT,
  VERIFICACION_DOS_PASOS_TITLE,
  TEXTO_CONSULTAR_DNIC,
  TEXTO_DATOS_PERSONALES,
  TEXTO_DATOS_DNIC,
  TEXTO_DATOS_CONTACTO,
  TEXTO_BOTON_MODIFICAR,
  TEXTO_BOTON_OBTENER_DATOS,
  TEXTO_BOTON_SI,
  TEXTO_BOTON_NO,
  TEXTO_PRIMER_NOMBRE,
  TEXTO_SEGUNDO_NOMBRE,
  TEXTO_PRIMER_APELLIDO,
  TEXTO_SEGUNDO_APELLIDO,
  TEXTO_TIPO_DOCUMENTO,
  TEXTO_PAIS_EMISION,
  TEXTO_NUMERO_DOCUMENTO,
  TEXTO_CORREO_ELECTRONICO,
  TEXTO_ACTIVADA,
  TEXTO_FECHA_ACTIVACION,
  TEXTO_DESACTIVADA,
  TEXTO_FECHA,
  TEXTO_ORIGEN,
  TEXTO_NOMBRE_EN_CEDULA,
  ELIMINAR_USUARIO,
  TEXTO_NIVEL_CONFIANZA,
  TEXTO_SEGURIDAD,
  TEXTO_VERIFICAR,
  TEXTO_VERIFICAR_AHORA,
  TEXTO_ENTIENDO,
  TEXTO_PERFIL_CIUDADANO,
  TEXTO_DOCUMENTO,
  TEXTO_DATOS_REGISTRO,
  obtenerTextoEliminarCiudadano,
  obtenerTextoVerificarCiudadano,
  TEXTO_ULTIMO_INGRESO,
  TEXTO_NUNCA_INGRESO,
  ACTIVIDAD_TITLE,
  TEXTO_BOTON_OBTENER_ULTIMAS_ACTIVIDADES,
  TEXTO_BOTON_CONSULTAR_CEDULA,
  TEXTO_TITULO_CEDULA_CIUDADANO,
  TEXTO_NUMERO_DE_SERIE_INPUT,
  TEXTO_DATOS_TELEFONO,
  TEXTO_TELEFONO,
} from 'constants/commonConstants';
import { CAMBIAR_TELEFONO_ROUTE } from 'constants/RouterConstants';

// utils
import { obtenerNombreCompleto, formatearFechaCompleta } from 'utils/utils';

// permisos
import usePermissions from 'hooks/usePermissions';
import {
  FF_OBTENER_INFO_DNIC,
  PERMISO_ELIMINAR_CIUDADANO,
  PERMISO_MODIFICAR_INFO_PERSONAL_CUALQUIER_CIUDADANO,
  PERMISO_VALIDAR_CIUDADANO,
  PERMISO_MODIFICAR_CORREO_CUALQUIER_CIUDADANO,
  PERMISO_REENVIAR_ACTIVACION_CUALQUIER_CIUDADANO,
} from 'constants/permissionsConstants';

// componentes
import ModalConfirmarAccionConInput from 'components/ModalConfirmarAccionConInput';
import TablaAcciones from 'components/TablaAcciones/tablaAcciones';
import Card from '../Card';
import CardSpinner from '../CardSpinner';
import TitleCard from '../TitleCard';
import SubtitleCard from '../SubtitleCard';
import InfoSection from '../InfoSection';
import InfoSectionConAccion from '../InfoSectionConAccion';
import ModalVerificarCiudadano from '../ModalVerificarCiudadano';
import ModalConfirmarAccion from '../ModalConfirmarAccion';
import Alert from '../Alert';

// styles
import styles from './styles.module.css';

/**
 * Este componente muestra los datos de un ciudadano. Permite modificar, eliminar y verificar
 * el ciudadano, así como también reenviarle el mail de desbloqueo o activación.
 *
 * @param ciudadano - Los datos del ciudadano seleccionado.
 * @param cargandoCiudadano - Determina si la acción de traer el ciudadano está cargando o no.
 * @param handleObtenerDatosDNIC - Función para obtener los datos del ciudadano de DNIC.
 * @param handleObtenerCedula - Función para obtener la cédula del ciudadano.
 * @param handleActualizarDatosPersonales - Función para ejecutar la actualización de datos personales.
 * @param handleActualizarEmailCiudadano - Función para ejecutar la actualización de datos de contacto.
 * @param handleEliminarCiudadano - Función para ejecutar la eliminación del ciudadano.
 * @param handleReenviarEmail - Función para reenviar el email de activación o desbloqueo según corresponda.
 * @param handleVerificarCiudadano - Función para pasar el ciudadano a nivel presencial.
 * @param handleObtenerUltimasAccionesCiudadano - Función para obtener las últimas acciones del ciudadano.
 * @returns {React.JSX.Element} - El JSX que renderiza el componente.
 */
const Perfil = ({
  ciudadano,
  cargandoCiudadano,
  handleObtenerDatosDNIC,
  handleObtenerCedula,
  handleActualizarDatosPersonales,
  handleActualizarEmailCiudadano,
  handleEliminarCiudadano,
  handleReenviarEmail,
  handleVerificarCiudadano,
  handleObtenerUltimasAccionesCiudadano,
}) => {
  const history = useHistory();
  const [camposDNIC, setCamposDNIC] = useState(null);
  const [ultimasAccionesCiudadano, setUltimasAccionesCiudadano] = useState([]);

  // Cedula
  const [trajoInfoDnic, setTrajoInfoDnic] = useState(false);
  const [nroSerie, setNroSerie] = useState('');
  const [frenteCedulaB64Encoded, setFrenteCedulab64Encoded] = useState(null);

  // modales
  const [mostrarModalReenviarEmail, setMostrarModalReenviarEmail] =
    useState(false);
  const [mostrarModalVerificarCiudadano, setMostrarModalVerificarCiudadano] =
    useState(false);
  const [mostrarModalEliminarCiudadano, setMostrarModalEliminarCiudadano] =
    useState(false);
  const [mostrarModalEmailNoValidado, setMostrarModalEmailNoValidado] =
    useState(false);

  // cargando
  const [cargandoReenviarEmail, setCargandoReenviarEmail] = useState(false);
  const [cargandoVerificarCiudadano, setCargandoVerificarCiudadano] =
    useState(false);
  const [cargandoEliminarCiudadano, setCargandoEliminarCiudadano] =
    useState(false);

  // permisos
  const puedeObtenerInfoDnic = usePermissions([FF_OBTENER_INFO_DNIC]);
  const puedeEliminarCiudadano = usePermissions([PERMISO_ELIMINAR_CIUDADANO]);
  const puedeModificarInfoCualquierCiudadano = usePermissions([
    PERMISO_MODIFICAR_INFO_PERSONAL_CUALQUIER_CIUDADANO,
  ]);
  const puedeReenviarActivacion = usePermissions([
    PERMISO_REENVIAR_ACTIVACION_CUALQUIER_CIUDADANO,
  ]);
  const tienePermisoModificarCorreo = usePermissions([
    PERMISO_MODIFICAR_CORREO_CUALQUIER_CIUDADANO,
  ]);
  const puedeVerificarCiudadano = usePermissions([PERMISO_VALIDAR_CIUDADANO]);
  const puedeModificarEmail = !ciudadano?.email_validado && ciudadano?.visible;

  // constantes
  const mfaActivado = ciudadano?.mfa?.activado || false;
  const nombreCompleto = obtenerNombreCompleto(ciudadano);
  const textoModalEliminarCiudadano = ELIMINAR_CIUDADANO_TEXT(
    nombreCompleto,
    ciudadano?.primer_apellido,
  );
  const infoAlertaEmail = () => {
    if (ciudadano?.email_por_validar !== null && !ciudadano?.email_validado) {
      if (ciudadano?.cuenta_activa) {
        return {
          textoAlertaEmail: REVERIFICAR_CORREO_TEXT(
            ciudadano?.email_por_validar.email,
          ),
          textoLinkAlertaEmail: puedeReenviarActivacion
            ? REVERIFICAR_CORREO_LINK_TEXT
            : null,
        };
      }
      return {
        textoAlertaEmail: ACTIVAR_CUENTA_TEXT,
        textoLinkAlertaEmail: puedeReenviarActivacion
          ? ACTIVAR_CUENTA_LINK_TEXT
          : null,
      };
    }
    if (ciudadano?.bloqueado) {
      return {
        textoAlertaEmail: CUENTA_BLOQUEADA,
        textoLinkAlertaEmail: DESBLOQUEO_CUENTA_LINK_TEXT,
      };
    }
    return {
      textoAlertaEmail: null,
      textoLinkAlertaEmail: TEXTO_VERIFICAR_AHORA,
    };
  };
  const { textoLinkAlertaEmail, textoAlertaEmail } = infoAlertaEmail();

  const mostrarAlerta =
    // casos true:
    // bloqueado: ciudadano creado que no activó cuenta o que se bloqueó en flujo de SOAP
    // !bloqueado:
    // email_por_validar && !email_validado && cuenta_activa: cambio de mail de ciudadano que ya había activado cuenta
    // email_por_validar && email_validado && !cuenta_activa: estado inconsistente
    (ciudadano?.email_por_validar &&
      (!ciudadano?.email_validado || !ciudadano?.cuenta_activa)) ||
    ciudadano?.bloqueado;

  // campos
  const datosPersonales = [
    {
      label: TEXTO_PRIMER_NOMBRE,
      valor: ciudadano?.primer_nombre,
      nombre: 'primer_nombre',
      requerido: true,
    },
    {
      label: TEXTO_SEGUNDO_NOMBRE,
      valor: ciudadano?.segundo_nombre,
      nombre: 'segundo_nombre',
      requerido: false,
    },
    {
      label: TEXTO_PRIMER_APELLIDO,
      valor: ciudadano?.primer_apellido,
      nombre: 'primer_apellido',
      requerido: true,
    },
    {
      label: TEXTO_SEGUNDO_APELLIDO,
      valor: ciudadano?.segundo_apellido,
      nombre: 'segundo_apellido',
      requerido: false,
    },
  ];
  const datosDocumentos = [
    {
      label: TEXTO_TIPO_DOCUMENTO,
      valor: ciudadano?.documento?.tipo_documento?.nombre,
    },
    {
      label: TEXTO_PAIS_EMISION,
      valor: ciudadano?.documento?.pais_emisor?.nombre,
    },
    {
      label: TEXTO_NUMERO_DOCUMENTO,
      valor: ciudadano?.numero_documento,
    },
  ];
  const datosContacto = [
    {
      label: TEXTO_CORREO_ELECTRONICO,
      valor: ciudadano?.user?.email,
      nombre: 'email',
      requerido: true,
    },
  ];
  const datosTelefono = [
    {
      label: TEXTO_TELEFONO,
      valor: ciudadano?.numero_telefono && `+${ciudadano?.numero_telefono}`,
      nombre: 'numero_telefono',
      requerido: true,
    },
  ];

  const nivelSeguridadTexto = ciudadano.nivel_seguridad_display;

  const datosSeguridad = [
    {
      label: TEXTO_NIVEL_CONFIANZA,
      valor: nivelSeguridadTexto,
    },
  ];
  const datosMFA = mfaActivado
    ? [
        {
          label: VERIFICACION_DOS_PASOS_TITLE,
          valor: TEXTO_ACTIVADA,
        },
        {
          label: TEXTO_FECHA_ACTIVACION,
          valor: formatearFechaCompleta(ciudadano?.mfa.fecha_activado),
        },
      ]
    : [
        {
          label: `${VERIFICACION_DOS_PASOS_TITLE}:`,
          valor: TEXTO_DESACTIVADA,
        },
      ];

  const datosRegistro = [
    {
      label: TEXTO_FECHA,
      valor: formatearFechaCompleta(ciudadano?.user?.date_joined),
    },
    {
      label: TEXTO_ORIGEN,
      valor: ciudadano?.origen_registro?.nombre,
    },
  ];

  const datosUltimasActividades = [
    {
      label: TEXTO_ULTIMO_INGRESO,
      valor: formatearFechaCompleta(
        ciudadano.ultimo_login,
        TEXTO_NUNCA_INGRESO,
      ),
    },
  ];

  const datosDNIC = data => {
    if (!data) return null;
    return [
      {
        label: TEXTO_PRIMER_NOMBRE,
        valor: data?.primer_nombre,
      },
      {
        label: TEXTO_SEGUNDO_NOMBRE,
        valor: data?.segundo_nombre,
      },
      {
        label: TEXTO_PRIMER_APELLIDO,
        valor: data?.primer_apellido,
      },
      {
        label: TEXTO_SEGUNDO_APELLIDO,
        valor: data?.segundo_apellido,
      },
      {
        label: TEXTO_NOMBRE_EN_CEDULA,
        valor: data?.nombre_en_cedula,
      },
      {
        label: TEXTO_NUMERO_DOCUMENTO,
        valor: data?.numero_documento,
      },
    ];
  };

  // componentes extra
  const alertaMail = mostrarAlerta ? (
    <Alert
      class="alert-warning"
      type="warning"
      text={textoAlertaEmail}
      link="/"
      linkText={textoLinkAlertaEmail}
      linkOnClick={() => setMostrarModalReenviarEmail(true)}
    />
  ) : null;

  // acciones
  const obtenerDatosDNIC = async () => {
    const respuesta = await handleObtenerDatosDNIC();
    if (respuesta) {
      setCamposDNIC(datosDNIC(respuesta.data));
    }
    setTrajoInfoDnic(true);
  };

  const obtenerCedula = async () => {
    const respuesta = await handleObtenerCedula(nroSerie);
    if (respuesta) {
      setFrenteCedulab64Encoded(respuesta.data?.foto_delantera);
    }
  };

  const eliminarCiudadano = async () => {
    setCargandoEliminarCiudadano(true);
    await handleEliminarCiudadano(ciudadano.id);
    setCargandoEliminarCiudadano(false);
  };

  const reenviarEmail = async () => {
    setCargandoReenviarEmail(true);
    await handleReenviarEmail();
    setCargandoReenviarEmail(false);
    setMostrarModalReenviarEmail(false);
  };

  const verificarCiudadano = async () => {
    setCargandoVerificarCiudadano(true);
    await handleVerificarCiudadano(ciudadano.id);
    setCargandoVerificarCiudadano(false);
    setMostrarModalVerificarCiudadano(false);
  };

  const obtenerUltimasAccionesCiudadano = async () => {
    const ultimasAcciones = await handleObtenerUltimasAccionesCiudadano(
      ciudadano.id,
    );
    setUltimasAccionesCiudadano(ultimasAcciones);
  };

  // render
  if (cargandoCiudadano) {
    return <CardSpinner spin />;
  }

  return (
    <Card>
      <TitleCard title={TEXTO_PERFIL_CIUDADANO} />
      <SubtitleCard text={nombreCompleto} />
      {!ciudadano?.visible && (
        <Alert
          type="warning"
          class="alert-warning"
          text={CIUDADANO_NO_VISIBLE}
        />
      )}
      {/* Datos Personales ----------------------------------------------- */}
      <InfoSectionConAccion
        mostrarBoton={
          ciudadano?.visible && puedeModificarInfoCualquierCiudadano
        }
        titulo={TEXTO_DATOS_PERSONALES}
        campos={datosPersonales}
        textoBoton={TEXTO_BOTON_MODIFICAR}
        handleAccion={handleActualizarDatosPersonales}
        accionEsModificar
      />

      {/* Datos DNIC (solo si tiene documento uruguayo) ----------------------------------------------- */}
      {puedeObtenerInfoDnic &&
        ciudadano?.documento?.pais_emisor?.nombre.toLowerCase() ===
          'uruguay' && (
          <InfoSectionConAccion
            titulo={TEXTO_DATOS_DNIC}
            textoSinDatos={camposDNIC == null ? TEXTO_CONSULTAR_DNIC : null}
            campos={camposDNIC}
            mostrarBoton={camposDNIC == null}
            textoBoton={TEXTO_BOTON_OBTENER_DATOS}
            handleAccion={obtenerDatosDNIC}
            contenidoExtra={
              trajoInfoDnic && (
                <InfoSectionConAccion
                  titulo={TEXTO_TITULO_CEDULA_CIUDADANO}
                  campos={[]}
                  mostrarBoton={frenteCedulaB64Encoded === null}
                  textoBoton={TEXTO_BOTON_CONSULTAR_CEDULA}
                  handleAccion={obtenerCedula}
                  contenidoExtra={
                    <div className={styles.boxCedula}>
                      <TextField
                        label={TEXTO_NUMERO_DE_SERIE_INPUT}
                        value={nroSerie}
                        name="nro-serie"
                        id="nro-serie"
                        type="text"
                        InputLabelProps={{ shrink: true }}
                        onChange={e => setNroSerie(e.target.value)}
                      />
                      {frenteCedulaB64Encoded && (
                        <img
                          src={`data:/image/jpeg;base64, ${frenteCedulaB64Encoded}`}
                          alt="frente-cedula"
                        />
                      )}
                    </div>
                  }
                />
              )
            }
          />
        )}

      {/* Documento ------------------------------------------------------ */}
      <InfoSection titulo={TEXTO_DOCUMENTO} campos={datosDocumentos} />

      {/* Datos de Contacto ---------------------------------------------- */}
      <InfoSectionConAccion
        titulo={TEXTO_DATOS_CONTACTO}
        campos={datosContacto}
        mostrarBoton={
          ciudadano?.visible &&
          puedeModificarEmail &&
          tienePermisoModificarCorreo
        }
        handleAccion={handleActualizarEmailCiudadano}
        textoBoton={TEXTO_BOTON_MODIFICAR}
        contenidoExtra={alertaMail}
        accionEsModificar
      />

      <ModalConfirmarAccion
        isOpen={mostrarModalReenviarEmail}
        onClose={() => setMostrarModalReenviarEmail(false)}
        text={MODAL_VERIFICAR_MAIL}
        buttonYesCallback={reenviarEmail}
        buttonNoCallback={() => setMostrarModalReenviarEmail(false)}
        loading={cargandoReenviarEmail}
        buttonTextPositiveAction={TEXTO_BOTON_SI}
        buttonTextNegativeAction={TEXTO_BOTON_NO}
      />

      {/* Teléfono ---------------------------------------------- */}
      <InfoSectionConAccion
        titulo={TEXTO_DATOS_TELEFONO}
        campos={datosTelefono}
        mostrarBoton={
          ciudadano?.visible && puedeModificarInfoCualquierCiudadano
        }
        textoBoton={TEXTO_BOTON_MODIFICAR}
        accionEsModificar
        handleBotonExcepcional={() => history.push(CAMBIAR_TELEFONO_ROUTE)}
      />

      {/* Registro --------------------------------------------------- */}
      <InfoSection titulo={TEXTO_DATOS_REGISTRO} campos={datosRegistro} />

      {/* Verificación en dos pasos ------------------------------------ */}
      <InfoSection titulo={VERIFICACION_DOS_PASOS_TITLE} campos={datosMFA} />

      {/* Actividad ------------------------------------ */}
      <InfoSectionConAccion
        titulo={ACTIVIDAD_TITLE}
        campos={datosUltimasActividades}
        mostrarBoton={ultimasAccionesCiudadano.length === 0}
        textoBoton={TEXTO_BOTON_OBTENER_ULTIMAS_ACTIVIDADES}
        handleAccion={obtenerUltimasAccionesCiudadano}
        contenidoExtra={
          ultimasAccionesCiudadano.length > 0 && (
            <TablaAcciones acciones={ultimasAccionesCiudadano} />
          )
        }
      />

      {/* Seguridad ------------------------------------------------------ */}
      <InfoSectionConAccion
        titulo={TEXTO_SEGURIDAD}
        campos={datosSeguridad}
        mostrarBoton={
          ciudadano?.nivel_de_confianza?.key ===
            NIVEL_DE_CONFIANZA_AUTOREGISTRADO &&
          puedeVerificarCiudadano &&
          ciudadano?.visible
        }
        handleAccion={
          ciudadano?.email_validado
            ? () => setMostrarModalVerificarCiudadano(true)
            : () => setMostrarModalEmailNoValidado(true)
        }
        textoBoton={TEXTO_VERIFICAR}
      />
      <ModalVerificarCiudadano
        abierto={mostrarModalVerificarCiudadano}
        onClose={() => setMostrarModalVerificarCiudadano(false)}
        campos={datosPersonales.concat(datosDocumentos)}
        texto={MODAL_VERIFICAR_CIUDADANO_TEXT}
        textoBoton={obtenerTextoVerificarCiudadano(ciudadano?.primer_nombre)}
        callbackBoton={verificarCiudadano}
        cargando={cargandoVerificarCiudadano}
      />
      <ModalConfirmarAccion
        isOpen={mostrarModalEmailNoValidado}
        onClose={() => setMostrarModalEmailNoValidado(false)}
        text={
          ciudadano?.cuenta_activa
            ? MODAL_EMAIL_NO_VALIDADO_CIUDADANO_TEXT
            : MODAL_CUENTA_NO_ACTIVADA_CIUDADANO_TEXT
        }
        buttonYesCallback={() => setMostrarModalEmailNoValidado(false)}
        buttonTextPositiveAction={TEXTO_ENTIENDO}
      />

      <div>
        {/* Eliminar ----------------------------------------------------- */}
        {puedeEliminarCiudadano && (
          <button
            type="button"
            className={styles.accionRiesgosa}
            onClick={() => setMostrarModalEliminarCiudadano(true)}
          >
            {ELIMINAR_USUARIO}
          </button>
        )}
        <ModalConfirmarAccionConInput
          abierto={mostrarModalEliminarCiudadano}
          onClose={() => setMostrarModalEliminarCiudadano(false)}
          textoAComparar={ciudadano?.primer_apellido}
          textoBoton={obtenerTextoEliminarCiudadano(ciudadano?.primer_nombre)}
          handleBoton={eliminarCiudadano}
          textoModal={textoModalEliminarCiudadano}
          cargando={cargandoEliminarCiudadano}
          textoLabelInput={TEXTO_PRIMER_APELLIDO}
          nombreCampo="primerApellido"
        />
      </div>
    </Card>
  );
};

Perfil.propTypes = {
  ciudadano: PropTypes.object,
  cargandoCiudadano: PropTypes.bool,
  handleObtenerDatosDNIC: PropTypes.func,
  handleObtenerCedula: PropTypes.func,
  handleActualizarDatosPersonales: PropTypes.func,
  handleActualizarEmailCiudadano: PropTypes.func,
  handleEliminarCiudadano: PropTypes.func,
  handleReenviarEmail: PropTypes.func,
  handleVerificarCiudadano: PropTypes.func,
  handleObtenerUltimasAccionesCiudadano: PropTypes.func,
};

export default Perfil;
